import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import { LayoutGlobal } from "../../../Layout";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import ViewGroup from "../../Framework/ViewGroup";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class BrandEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }


    render() {
        return (
            <View title="Edit Brand">
                <UForm api="/api/brand"
                model={this.state.info}
                id={this.props.params.projectID}
                onLoad={(info) => this.setState({ info: {...info, projectID: parseInt(this.props.params.projectID)} })}
                goBack={() => this.props.navigate(-1)}>
                    <ViewGroup>
                        <FormInputV2 model={this.state.info} name="name" label="Brand Name" required />
                    </ViewGroup>
                    <AppActions showBack>
                        <SaveButton />
                    </AppActions>
                </UForm>
            </View>
        );
    }
}

export default withParamsAndNavigate(BrandEdit);