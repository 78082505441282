import BaseCom from "../BaseCom";
import Icon from "../Icon";
import L from "../Lang";
import { LayoutGlobal } from "../Layout";
import AppOptions from "../Solusek/AppOptions";
import DataView from "../Solusek/DataView";
import View from "../Solusek/Framework/View";
import OptionButton from "../Solusek/OptionButton";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class LocationList extends BaseCom {

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    render() {
        return (
            <View title="Location List">
                <AppOptions>
                    <OptionButton primary link="new"><L>New Location</L></OptionButton>
                </AppOptions>
                <DataView
                    hash={this.state.hash}
                    api="/api/passage/location/list"
                    actions={[
                        { label: <Icon icon="faPencil" />, onClick: (row) => this.props.navigate(String(row.id) + '/edit') },
                        { label: <Icon icon="faTrash" />, onClick: (row) => this.deleteProject(row), danger: true }
                    ]}
                    onRowClick={(row) => this.props.navigate(String(row.id))}
                />
            </View>
        );
    }
}

export default withParamsAndNavigate(LocationList);