import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import { Pop } from "./PopModal";
import Tick from "./Tick";

export default class CheckVer extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            version: null
        };
    }

    tick = async () => {
        try {
            let j = await qreq.getAsync('/api/version');
            if(j.item) {
                if(this.state.version !== null && this.state.version !== j.item) {
                    Pop.showConfirm(<>The portal version has changed from <b>{this.state.version}</b> to <b>{j.item}</b>. Please <i>Confirm</i> to refresh and view the newest version.</>, 'New Version', () => {
                        window.location.reload(true);
                    });
                }
                this.setState({ version: j.item });
            }
        }
        catch {}
      }

    render() {
        return <Tick onTick={this.tick} interval={10000} />;
    }
}