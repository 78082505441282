import BaseCom from "../BaseCom";
import L from "../Lang";
import { LayoutGlobal } from "../Layout";
import AppOptions from "../Solusek/AppOptions";
import View from "../Solusek/Framework/View";
import OptionButton from "../Solusek/OptionButton";
import SaveButton from "../Solusek/SaveButton";
import UForm from "../Solusek/UForm";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class LocationEdit extends BaseCom {

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    render() {
        return (
            <View title="Location Edit">
                <UForm api="/api/passage/location"
                    model={this.state.info}
                    id={this.props.params.id}
                    onLoad={(info) => this.setState({ info: info })}
                    goBack={() => this.props.navigate(-1)}>
                    <AppOptions>
                        <SaveButton />
                    </AppOptions>
                </UForm>
            </View>
        );
    }
}

export default withParamsAndNavigate(LocationEdit);